import React, { useState } from "react"
import {
  LegacyCard,
  Text,
  Collapsible,
  FormLayout,
  Select,
  Box,
  LegacyStack,
  Icon,
  ButtonGroup,
  Button,
} from "@shopify/polaris"
import { UploadIcon } from "@shopify/polaris-icons";
import update from "immutability-helper"
import ImagesSelector from "../customizations/customcontent/ImagesSelector"
import CardDivider from "../../../components/cardDivider";
export default function GlobalForm({
  ButtonWrapper,
  dropdownButtonMarkup,
  state,
  setState,
  token,
  shop,
  host,
}) {
  const [open, setOpen] = useState(0)
  const [img, setImg] = useState(null)
  const handleToggle = (index) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }
  const onImageChange = (img) => {
    console.log(img)
    setImg(img)
    //fields.faviconImage.onChange(img.id)
    setState(update(state, { faviconImage: { $set: img.id } }))
  }
  return (
    <LegacyCard title="Global">
      <CardDivider/>
      <LegacyCard.Section>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            handleToggle(0)
          }}
          ariaExpanded={open === 0}
          ariaControls={`corner-radius-collapsible`}
        >
          {dropdownButtonMarkup(
            "Corner radius",
            "Applies to all elements, like buttons, cards and input forms",
            open === 0
          )}
        </ButtonWrapper>
      </LegacyCard.Section>
      {open === 0 && (
        <LegacyCard.Section>
          <Collapsible
            open={open === 0}
            id={`corner-radius-collapsible`}
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            <LegacyCard sectioned subdued>
              <FormLayout>
                <Select
                  label="Corner radius"
                  labelHidden
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.globalCornerRadius}
                  onChange={(value) => {
                    setState(
                      update(state, { globalCornerRadius: { $set: value } })
                    )
                  }}
                />
              </FormLayout>
            </LegacyCard>
          </Collapsible>
        </LegacyCard.Section>
      )}
      <LegacyCard.Section>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            handleToggle(1)
          }}
          ariaExpanded={open === 1}
          ariaControls={`favicon-collapsible`}
        >
          {dropdownButtonMarkup(
            "Favicon",
            "Update the browser favicon",
            open === 1
          )}
        </ButtonWrapper>
      </LegacyCard.Section>
      {open === 1 && (
        <LegacyCard.Section>
          <Collapsible
            open={open === 1}
            id={`favicon-collapsible`}
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            <LegacyCard sectioned subdued>
              <FormLayout>
              <div
                style ={{
                  borderStyle:"dashed",
                  minHeight:"165px",
                  minWidth:"500px",
                  background:"bg-interactive-disabled",
                  borderColor:"border-strong",
                  borderWidth:"base",
                  borderRadius:"large",
                  }}
                >
                  {img ? (
                    <img
                      src={img.image.originalSrc}
                      alt="Selected image"
                      style={{ maxWidth: "500px" }}
                    />
                  ) : (
                    <div style={{ padding: "35px 0" }}>
                      <LegacyStack gap="10px" vertical alignment="center">
                        <Icon source={UploadIcon} tone="subdued" />
                        <ImagesSelector token={token} shop={shop} host={host} onChange={onImageChange} type="IMAGE" />
                      </LegacyStack>
                    </div>
                  )}
                </div>
                {img && (
                  <ButtonGroup variant="segmented" fullWidth>
                    <ImagesSelector token={token} shop={shop} host={host} onChange={onImageChange} type="IMAGE" />
                    <Button onClick={() => onImageChange(null)}>Remove</Button>
                  </ButtonGroup>
                )}
              </FormLayout>
            </LegacyCard>
          </Collapsible>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  );
}
